import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import FullWidthCard from "../components/fullWidthCard"
import { TEMP_IMAGES } from "../model/constats"

const OChatePage = (props) => {
  if (typeof window !== 'undefined') {
    navigate('/');
    return null;
  }

  return (
    <Layout>
      <SEO title="O chatě"/>
      <div className="container mx-auto p-8 mb-3">
        <h1 className="text-5xl text-gray-900">
          O chatě
        </h1>

        <div className="mt-5">
          <p className="text-gray-600">
            Kapacita 10 až 14 osob. 3 dvojlůžkové pokoje s vlastním sociálním zařízením a balkónem. 2 apartmány s
            vlastním
            sociálním zařízením (vč. vany), obývacím pokojem s možností přistýlky a balkónem. Lůžkoviny jsou v ceně (bez
            ručníků). V přízemí je velká společenská místnost s TV, jídelnou a plně vybavená společná kuchyňka. Před
            chatou je kryté venkovní posezení s přenosným grilem.
          </p>

          <div className="mt-5">
            <Gallery images={TEMP_IMAGES}/>
          </div>
        </div>

        <h2 className="text-3xl text-gray-900 mt-10">
          Pokoje
        </h2>
        <div className="mb-10">
          <FullWidthCard
            title={"Apartmán"}
            subtitle={"Dvakrát"}
            text={"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer imperdiet lectus quis justo. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Sed convallis magna eu sem. In rutrum. Maecenas fermentum, sem in pharetra pellentesque, velit turpis volutpat ante, in pharetra metus odio a lectus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris elementum mauris vitae tortor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Integer imperdiet lectus quis justo. Etiam quis quam. Morbi scelerisque luctus velit."}
          />
        </div>
        <div className="">
          <FullWidthCard
            title={"Standartní pokoj"}
            subtitle={"Tříkrát"}
            text={"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer imperdiet lectus quis justo. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Sed convallis magna eu sem. In rutrum. Maecenas fermentum, sem in pharetra pellentesque, velit turpis volutpat ante, in pharetra metus odio a lectus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Mauris elementum mauris vitae tortor. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Integer imperdiet lectus quis justo. Etiam quis quam. Morbi scelerisque luctus velit."}
          />
        </div>

      </div>

    </Layout>
  )
}

export default OChatePage
