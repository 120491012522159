import React from 'react';

import { TEMP_IMAGES } from "../model/constats"
import Gallery from "./gallery"

const FullWidthCard = props => {
	return (
    <div className="w-full lg:max-w-full lg:flex mt-3 shadow-xl">
      <div
        className="border-white h-48 lg:h-auto lg:w-2/5 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
        style={{ backgroundImage: `url('https://images.unsplash.com/photo-1475855581690-80accde3ae2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80')` }}
        title="Woman holding a mug"
      >
      </div>
      <RightText {...props} />
    </div>
	);
};

const RightText = (props) => (
  <div className="border-r border-b border-l lg:border-l-0 lg:border-t bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
    <div className="mb-8">
      <p className="text-sm text-gray-600 flex items-center">
        { props.subtitle }
      </p>
      <div className="text-gray-900 font-bold text-xl mb-2">
        { props.title }
      </div>
      <p className="text-gray-700 text-base">
        { props.text }
      </p>
      <div className="mt-5" style={{ marginBottom: '-2.5rem', }}>
        <Gallery images={ TEMP_IMAGES }/>
      </div>
    </div>
  </div>
)

export default FullWidthCard;
